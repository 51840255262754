import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Node } from 'djedi-react';
import { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';

import ClosedEyeSVG from 'assets/icons/closed-eye.svg';
import OpenEyeSVG from 'assets/icons/open-eye.svg';
import AccountStepThree from 'assets/images/account-step-3.svg';
import DarkLogo from 'assets/images/logo-black-text.svg';
import Clickable from 'components/Clickable';
import Column from 'components/Column';
import Heading from 'components/Heading';
import inputClasses from 'components/Input/Input.module.scss';
import Row from 'components/Row';
import Typography from 'components/Typography';
import { useUser } from 'hooks';
import classes from 'styles/pages/LoginPage.module.scss';
import { cx } from 'utils';

type LoginValues = {
  email: string;
  password: string;
  remember_me: boolean;
  plain_text_password: boolean;
};

const LoginPage: NextPage = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { login } = useUser();

  const [form, setForm] = React.useState<LoginValues>({
    email: '',
    password: '',
    remember_me: false,
    plain_text_password: false,
  });

  const mutation = useMutation({
    mutationFn: async ({ email, password, remember_me }: LoginValues) => {
      return await login(email, password, remember_me);
    },
    onSuccess: async ({ success }) => {
      if (success) {
        queryClient.clear();
        await router.push('/');
      }
    },
  });

  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | boolean = e.target.value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }

    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutateAsync(form);
  };

  return (
    <div className={classes['container']}>
      <div className={classes['inner']}>
        <Row>
          <Column center="both" size={12}>
            <DarkLogo />
          </Column>
        </Row>
        <Row gap="none">
          <Column size={[12, 6]} className={cx(classes['card'], classes['left'])}>
            <Heading className={classes['title']} type="h2">
              Logga in
            </Heading>
            <form onSubmit={handleSubmit} className={classes['form']}>
              <div className={cx(inputClasses['container'], classes['input'])}>
                <label className={inputClasses['label']}>Epost</label>
                <input
                  className={cx(inputClasses['input'], inputClasses['native'])}
                  name="email"
                  onChange={handleUpdate}
                  type="email"
                  required
                />
              </div>
              <div className={cx(inputClasses['container'], classes['input'])}>
                <div className={classes['show-password']}>
                  <label className={cx(inputClasses['label'])}>Lösenord</label>
                  <label className={classes['eye']}>
                    <input
                      tabIndex={-1}
                      name="plain_text_password"
                      type="checkbox"
                      onChange={handleUpdate}
                      style={{ display: 'none' }}
                    />{' '}
                    {form.plain_text_password ? (
                      <ClosedEyeSVG className={classes['icon']} />
                    ) : (
                      <OpenEyeSVG className={classes['icon']} />
                    )}
                  </label>
                </div>
                <input
                  className={cx(inputClasses['input'], inputClasses['native'])}
                  name="password"
                  onChange={handleUpdate}
                  type={form.plain_text_password ? 'text' : 'password'}
                  required
                />
              </div>
              <div className={classes['buttons']}>
                <div
                  className={cx(
                    inputClasses['container'],
                    inputClasses['checkbox'],
                    classes['rembember-me'],
                  )}
                >
                  <input
                    className={cx(inputClasses['input'], inputClasses['native'])}
                    name="remember_me"
                    onChange={handleUpdate}
                    type="checkbox"
                  />
                  <label className={inputClasses['label']}>Kom ihåg mig</label>
                </div>
                <Clickable variant="inline" href="/recovery/" className={classes['forgot']}>
                  Glömt ditt lösenord?
                </Clickable>
              </div>
              <Clickable
                type="submit"
                className={classes['submit']}
                loading={mutation.isLoading}
                id="submitButton"
              >
                Logga in
              </Clickable>
            </form>
            {mutation.isError ||
              (mutation.data?.success === false ? (
                <Typography className={classes['error']} color="ui-red" size="small">
                  {mutation.data.reason ? (
                    <Node uri="login/unauthorized">Felaktigt lösenord eller e-postaddress.</Node>
                  ) : (
                    <Node uri="login/unknown-error">
                      Kunde inte acceptera logga in, ladda om sidan och försök igen. Om felet
                      kvarstår kontakta oss.
                    </Node>
                  )}
                </Typography>
              ) : null)}
          </Column>
          <Column size={[12, 6]} className={cx(classes['card'], classes['right'])}>
            <AccountStepThree className={classes['image']} />
          </Column>
        </Row>
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  return { props: { auth: false } };
};

export default LoginPage;
